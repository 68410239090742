/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */

'use client';

import React from 'react';
import {useRouter} from 'next/navigation';
import {useSession} from 'next-auth/react';

import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Typography,
  AppBar,
} from '@mui/material';
import {
  Info,
  HelpOutlineOutlined,
  FeedbackOutlined,
  AddOutlined,
} from '@mui/icons-material';
import {
  FEEDBACK_FORM,
  HELP_FORM,
  DATA_CONNECTION_FORM,
  MAX_NAV_WIDTH,
} from 'types/common';

function CUI() {
  return (
    <Typography fontSize={16} fontWeight="medium" color="white" align="center">
      {' '}
      CUI{' '}
    </Typography>
  );
}

function Header() {
  return (
    <CssBaseline>
      <AppBar
        sx={{
          backgroundColor: '#502b85',
          height: 24,
          width: 1,
          position: 'fixed',
          opacity: 1,
        }}
        elevation={0}
      >
        <CUI />
      </AppBar>
      <Box
        sx={{
          height: 24,
          width: 1,
        }}
      />
    </CssBaseline>
  );
}

function CUIFooter() {
  return (
    <CssBaseline>
      <Box
        sx={{
          backgroundColor: '#502b85',
          height: 24,
          width: 1,
          position: 'fixed',
          left: 0,
          bottom: 0,
          right: 0,
          opacity: 1,
          elevation: 0,
          mt: 'auto',
        }}
      >
        <CUI />
      </Box>
    </CssBaseline>
  );
}

function Footer() {
  const router = useRouter();
  const {data: session} = useSession();
  return (
    <CssBaseline>
      {/* @ts-ignore */}
      {session && session?.error !== 'RefreshAccessTokenError' && (
        <Box
          component="footer"
          sx={{
            height: 176,
            width: '100%',
            position: 'relative',
            bottom: '24px',
            elevation: 0,
            mt: '20px',
            backgroundColor: 'grays.dark',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              justifyContent: 'center',
              maxWidth: MAX_NAV_WIDTH,
              margin: 'auto',
              pl: 4,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{mb: '20px'}}
            >
              <Grid item>
                <Box
                  component="img"
                  sx={{
                    cursor: 'pointer',
                    justifyContent: 'flex-start',
                    height: 40,
                    maxHeight: 200,
                    maxWidth: 300,
                    mt: '16px',
                  }}
                  gridColumn="span 2"
                  alt="DELVE Logo."
                  src="logo.svg"
                  onClick={() => router.push('/')}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <Grid sx={{mx: '24px'}}>
                    <Typography
                      sx={{
                        mt: '16px',
                        mb: '8px',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      variant="button"
                    >
                      <Info
                        sx={{
                          height: '18px',
                          width: '18px',
                          mr: '8px',
                        }}
                      />
                      Resources
                    </Typography>
                    <Grid container direction="column" alignItems="flex-start">
                      <Button
                        variant="text"
                        onClick={() => router.replace(`/policy?tab=about`)}
                        sx={{mx: '24px', color: 'white'}}
                      >
                        About DELVE
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => router.replace(`/policy?tab=guidance`)}
                        sx={{mx: '24px', color: 'white'}}
                      >
                        Guidance & Policy
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => router.replace(`/policy?tab=sources`)}
                        sx={{mx: '24px', color: 'white'}}
                      >
                        Data Sources
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid sx={{mx: '24px'}}>
                    <Typography
                      sx={{
                        mt: '16px',
                        mb: '8px',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      variant="button"
                    >
                      Forms
                    </Typography>
                    <Grid container direction="column" alignItems="flex-start">
                      <Button
                        variant="text"
                        startIcon={<HelpOutlineOutlined />}
                        onClick={() => window.open(HELP_FORM)}
                        sx={{color: 'white'}}
                      >
                        Help Request
                      </Button>
                      <Button
                        variant="text"
                        startIcon={<FeedbackOutlined />}
                        onClick={() => window.open(FEEDBACK_FORM)}
                        sx={{color: 'white'}}
                      >
                        Give Feedback
                      </Button>
                      <Button
                        variant="text"
                        startIcon={<AddOutlined />}
                        onClick={() => window.open(DATA_CONNECTION_FORM)}
                        sx={{color: 'white'}}
                      >
                        Data Connection
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </CssBaseline>
  );
}

export {Header, Footer, CUIFooter};
