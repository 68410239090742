/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */
/* eslint-disable import/no-extraneous-dependencies */
import {Styles, Settings} from 'react-chatbotify';

export const chatbotStyle: Styles = {
  botBubbleStyle: {
    textAlign: 'left',
    color: '#ffffff',
    borderRadius: '12px 12px 12px 12px',
    backgroundColor: '#6565d5',
    padding: '',
  },
  userBubbleStyle: {
    backgroundColor: '#e0e0e0',
    color: '#081822',
    borderRadius: '8px 8px 8px 8px',
  },
  chatInputContainerStyle: {
    backgroundColor: '#ffffff',
  },
  sendButtonStyle: {
    backgroundColor: '#202d37',
  },
  sendButtonHoveredStyle: {
    backgroundColor: '#202d37',
  },
  botOptionStyle: {
    color: '#0a7ac9',
    backgroundColor: '#ffffff',
    border: '1px solid #0a7ac9',
  },
  botOptionHoveredStyle: {
    color: '#ffffff',
    backgroundColor: '#0a7ac9',
  },
};

export const resourceOptionStyle = {
  borderRadius: '12px 12px 12px 12px',
  padding: '12px',
  margin: '12px 12px 0px',
  color: '#0a7ac9',
  backgroundColor: '#ffffff',
  border: '1px solid #0a7ac9',
  '&hover': {
    color: '#ffffff',
    backgroundColor: '#0a7ac9',
  },
};

export const settings: Settings = {
  isOpen: false,
  general: {
    primaryColor: '#6565d5',
    secondaryColor: '#6565d5',
    fontFamily: 'Inter, sans-serif',
    showHeader: true,
    showFooter: false,
    showInputRow: true,
    embedded: false,
    desktopEnabled: true,
    mobileEnabled: true,
    flowStartTrigger: 'ON_LOAD',
  },
  tooltip: {
    mode: 'CLOSE',
    text: 'Need help? Ask DELVE AI!',
  },
  chatButton: {
    icon: 'autoawesome.png',
  },
  notification: {
    disabled: true,
    defaultToggledOn: true,
    showCount: true,
  },
  audio: {
    disabled: true,
    defaultToggledOn: false,
    language: 'en-US',
    voiceNames: [
      'Microsoft David - English (United States)',
      'Alex (English - United States)',
    ],
    rate: 1,
    volume: 1,
  },
  chatHistory: {
    disabled: false,
    maxEntries: 30,
    storageKey: 'chat-history',
    viewChatHistoryButtonText: 'Load Chat History ⟳',
    chatHistoryLineBreakText: '----- Previous Chat History -----',
    autoLoad: false,
  },
  chatInput: {
    allowNewline: false,
    enabledPlaceholderText: 'Type your message...',
    disabledPlaceholderText: 'Please wait while we process your request...',
    showCharacterCount: false,
    characterLimit: -1,
    botDelay: 500,
    blockSpam: true,
    sendOptionOutput: true,
    sendCheckboxOutput: true,
    sendButtonIcon: 'sendicon.svg',
  },
  chatWindow: {
    showScrollbar: false,
    autoJumpToBottom: false,
    showMessagePrompt: true,
    messagePromptText: 'New Messages ↓',
    messagePromptOffset: 30,
  },
  sensitiveInput: {
    maskInTextArea: true,
    maskInUserBubble: true,
    asterisksCount: 10,
    hideInUserBubble: false,
  },
  userBubble: {
    animate: true,
    showAvatar: false,
    simStream: true,
    streamSpeed: 30,
    dangerouslySetInnerHtml: false,
  },
  botBubble: {
    animate: true,
    showAvatar: false,
    simStream: true,
    streamSpeed: 30,
    dangerouslySetInnerHtml: false,
  },
  voice: {
    disabled: true,
    defaultToggledOn: false,
    language: 'en-US',
    timeoutPeriod: 10000,
    autoSendDisabled: false,
    autoSendPeriod: 1000,
    sendAsAudio: false,
  },
  footer: {
    text: '',
    buttons: [],
  },
  advance: {
    useAdvancedMessages: false,
    useAdvancedSettings: false,
    useAdvancedPaths: false,
    useAdvancedStyles: false,
  },
};
