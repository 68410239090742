/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import React from 'react';
import {Button, ListItemIcon, ListItemText, ListItem} from '@mui/material';
import {
  AccountCircle,
  Person,
  Logout,
  FileUpload,
  FileDownload,
  Bookmark,
  InsertDriveFile,
} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';

interface NavButtonProps {
  handleClick: () => void;
}

export function UploadButton({handleClick}: NavButtonProps) {
  const theme = useTheme();
  return (
    <Button
      variant="text"
      startIcon={<FileUpload />}
      onClick={() => handleClick()}
      sx={{color: theme.palette.grays.light, mx: '8px'}}
    >
      Upload
    </Button>
  );
}

export function MyUploadsButton({handleClick}: NavButtonProps) {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <FileUpload fontSize="small" sx={{color: theme.palette.grays.light}} />
      </ListItemIcon>
      <ListItemText
        onClick={() => handleClick()}
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          '&:hover': {},
          color: theme.palette.grays.light,
        }}
        primary="My Uploads"
      />
    </ListItem>
  );
}

export function MyDownloadsButton({handleClick}: NavButtonProps) {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <FileDownload
          fontSize="small"
          sx={{color: theme.palette.grays.light}}
        />
      </ListItemIcon>
      <ListItemText
        onClick={() => handleClick()}
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          '&:hover': {},
          color: theme.palette.grays.light,
        }}
        primary="My Downloads"
      />
    </ListItem>
  );
}

export function MyBookmarksButton({handleClick}: NavButtonProps) {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <Bookmark fontSize="small" sx={{color: theme.palette.grays.light}} />
      </ListItemIcon>
      <ListItemText
        onClick={() => handleClick()}
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          '&:hover': {},
          color: theme.palette.grays.light,
        }}
        primary="My Bookmarks"
      />
    </ListItem>
  );
}

export function MyProfileButton({handleClick}: NavButtonProps) {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <Person fontSize="small" sx={{color: theme.palette.grays.light}} />
      </ListItemIcon>
      <ListItemText
        onClick={() => handleClick()}
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          '&:hover': {},
          color: theme.palette.grays.light,
        }}
        primary="My Profile"
      />
    </ListItem>
  );
}

export function LogoutButton({handleClick}: NavButtonProps) {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <Logout fontSize="small" sx={{color: theme.palette.grays.light}} />
      </ListItemIcon>
      <ListItemText
        onClick={() => handleClick()}
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          '&:hover': {},
          color: theme.palette.grays.light,
        }}
        primary="Log Out"
      />
    </ListItem>
  );
}

export function MyFilesHeading() {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <InsertDriveFile fontSize="small" sx={{color: theme.palette.divider}} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          color: theme.palette.divider,
          margin: '-20px',
        }}
        primary="My Files"
      />
    </ListItem>
  );
}

export function AccountHeading() {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <AccountCircle fontSize="small" sx={{color: theme.palette.divider}} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{variant: 'button'}}
        sx={{
          color: theme.palette.divider,
          margin: '-20px',
        }}
        primary="Account"
      />
    </ListItem>
  );
}
