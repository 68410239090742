/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

/* eslint-disable react/jsx-no-constructed-context-values */

'use client';

import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useMemo,
} from 'react';
import {FileWithPath} from 'react-dropzone';
import {SmartMetadata} from 'types/autoextract';

interface UploadContextType {
  nlpFile: FileWithPath | null;
  setNlpFile: (file: FileWithPath | null) => void;
  nlpFileData: SmartMetadata;
  setNlpFileData: (fileData: SmartMetadata) => void;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUploadContext must be used within a UploadProvider');
  }
  return context;
};

export function UploadProvider(props: PropsWithChildren) {
  const [nlpFile, setNlpFile] = useState<File | null>(null);
  const [nlpFileData, setNlpFileData] = useState<any>();
  const {children} = props;
  const contextValue = useMemo(
    () => ({nlpFile, setNlpFile, nlpFileData, setNlpFileData}),
    [nlpFile, setNlpFile, nlpFileData, setNlpFileData],
  );
  return (
    <UploadContext.Provider value={contextValue}>
      {children}
    </UploadContext.Provider>
  );
}

export const useUpload = () => {
  const context = React.useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useUpload must be used within an UploadProvider');
  }
  return context;
};
