/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import React, {useState, useEffect, useRef} from 'react';
import {useRouter} from 'next/navigation';
import {
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import {Filters} from 'types/search';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import useWindowSize from 'hooks/getWindowSize';
import {useDrawerContext} from 'context/drawer';
import {useSearchFieldContext} from 'context/searchfield';
import {useSearchHistoryContext} from 'context/searchHistory';
import {useSearchParamsContext} from 'context/searchParams';
import RESET_PARAMS from 'utils/search/search';
import {MAX_NAV_WIDTH} from 'types/common';

interface SearchProps {
  isLoading?: boolean;
}

export default function NavSearch({isLoading = false}: SearchProps) {
  const label = 'Search data, documents, and more...';
  const [screenWidth] = useWindowSize();
  const {searchFieldValue, setSearchFieldValue} = useSearchFieldContext();
  const {open, setOpen} = useDrawerContext();
  const [isPulsating, setIsPulsating] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {searchHistory, addSearch} = useSearchHistoryContext();
  const router = useRouter();
  const {setPersistentSearchParams} = useSearchParamsContext();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLInputElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const onSearch = (searchString: string) => {
    router.push(`/results?searchString=${encodeURIComponent(searchString)}`);
  };

  const openTips = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const handleTextFieldBlur = () => {
    if (!popperRef.current?.contains(document.activeElement)) {
      setHistoryOpen(false);
    }
    setIsFocused(false);
  };

  const handleFocus = () => {
    setHistoryOpen(true);
    setIsFocused(true);
  };

  const performSearch = (searchString: string) => {
    const newSearch: Filters = {
      searchString,
      sizeFilter: '',
      sourceFilter: [],
      distroFilter: [],
      typeFilter: [],
      keywordFilter: [],
      startDate: '',
      endDate: '',
      userFilter: '',
    };
    addSearch(newSearch);
    const tempParams = RESET_PARAMS;
    tempParams.searchString = searchString;
    setPersistentSearchParams(tempParams);
    onSearch(searchString);
  };

  const handleDropdownSelect = (value) => {
    setSearchFieldValue(value);
    performSearch(value);
    setHistoryOpen(false);
  };

  const clearSearch = () => {
    setSearchFieldValue('');
  };

  const getWidth = () => {
    let tempWidth = screenWidth;
    if (screenWidth > MAX_NAV_WIDTH) tempWidth = MAX_NAV_WIDTH;
    return 0.5 * (tempWidth - 150);
  };

  useEffect(() => {
    if (open) openTips();
    else close();
  }, [open]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchFieldValue && searchFieldValue !== '') {
      setIsPulsating(true);
      const timer = setTimeout(() => {
        setIsPulsating(false);
      }, 500); // Adjust the duration of pulsation as needed
      return () => clearTimeout(timer);
    }
  }, [searchFieldValue]);

  if (screenWidth && screenWidth !== 0)
    return (
      <Grid
        container
        direction="row"
        alignContent="center"
        justifyContent="center"
        height="100%"
        wrap="nowrap"
      >
        <Grid item alignContent="center">
          <TextField
            focused={isPulsating || isFocused}
            onFocus={handleFocus}
            onBlur={handleTextFieldBlur}
            onClick={() => setHistoryOpen(true)}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              const newValue = event.target.value;
              setSearchFieldValue(newValue);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && searchFieldValue !== '') {
                performSearch(searchFieldValue);
              }
            }}
            data-testid="searchString"
            id="searchString"
            placeholder={label}
            variant="outlined"
            size="small"
            name="Search bar"
            value={searchFieldValue}
            sx={{
              width: getWidth(),
              '.MuiOutlinedInput-root': {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
              },
              '.MuiOutlinedInput-input': {
                color: 'white',
              },
              '.MuiInputLabel-root': {
                color: 'white',
              },
              input: {
                color: 'white',
                '&::placeholder': {
                  opacity: 1,
                },
              },
              '& .MuiInputBase-root': {
                backgroundColor: 'searchBarGray.main',
                borderRadius: 20,
                color: '#fff',
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grays.main',
                opacity: 1,
              },
              '& .MuiInputBase-input': {
                color: '#fff',
              },
              '& .MuiInputBase-root:hover': {
                backgroundColor: 'searchBarGray.main',
                borderColor: '#ffffff',
              },
              '& .MuiInputBase-root.Mui-focused': {
                backgroundColor: 'searchBarGray.main',
                borderColor: '#ffffff',
              },
            }}
            InputLabelProps={{
              sx: {fontSize: 16, color: 'white'},
            }}
            InputProps={{
              autoComplete: 'off',
              onDoubleClick: (e) => (e.target as HTMLInputElement).select(),
              disableUnderline: true,
              ref: anchorRef,
              sx: {
                height: '48px',
                fontSize: 16,
                color: 'white',
                alignItems: 'center',
                px: '12px',
              },
              endAdornment: (
                <>
                  <IconButton onClick={clearSearch}>
                    <CloseIcon
                      sx={{
                        height: '24px',
                        width: '24px',
                        mr: '16px',
                        color: 'white',
                      }}
                      aria-label="Clear button"
                    />
                  </IconButton>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    color="white"
                    flexItem
                  />
                  <IconButton
                    disabled={isLoading}
                    onClick={() =>
                      searchFieldValue !== '' && performSearch(searchFieldValue)
                    }
                  >
                    <SearchIcon
                      sx={{
                        height: '24px',
                        width: '24px',
                        ml: '16px',
                        color: 'white',
                      }}
                      aria-label="Search button"
                    />
                  </IconButton>
                </>
              ),
            }}
          />
          {searchHistory.length > 1 && (
            <Popper
              open={historyOpen}
              anchorEl={anchorRef.current}
              placement="bottom-start"
              style={{width: anchorRef.current?.clientWidth}}
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 24],
                  },
                },
              ]}
              onMouseEnter={() => setHistoryOpen(true)}
              onMouseLeave={() => setHistoryOpen(false)}
              ref={popperRef}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setHistoryOpen(false)}>
                  <MenuList autoFocusItem={historyOpen}>
                    <MenuItem disabled sx={{mb: '8px'}}>
                      Recent searches
                    </MenuItem>
                    {searchHistory.slice(0, 5).map((option) => (
                      <MenuItem
                        key={option.searchString}
                        value={option.searchString}
                        onClick={() => {
                          handleDropdownSelect(option.searchString);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter')
                            handleDropdownSelect(option.searchString);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <SearchIcon
                          sx={{mr: '8px', color: 'rgba(0, 0, 0, 0.54)'}}
                        />
                        {option.searchString}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          )}
        </Grid>

        <Grid item alignContent="center">
          <IconButton
            size="small"
            onClick={() => openTips()}
            sx={{ml: '16px', color: 'divider'}}
          >
            <InfoIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  return null;
}
